const getHeaderLInks = (
  pageName,
  programUrl,
  noLinks,
  countryUrl = '',
  hideStatistics = false
) => {
  const singleCountries = ['/CCP', '/TCP'];
  let links = !noLinks
    ? [
        {
          key: 'home',
          name: 'Home',
          link: programUrl,
          headerView: true,
        },
        {
          key: 'about',
          name: 'About',
          link: programUrl + '/about',
          headerView: true,
        },
        {
          key: 'projects',
          name: 'Projects',
          link: programUrl + '/projects',
          headerView: true,
        },
        {
          key: 'ipi',
          name: 'Investment Promotion Institutions',
          link: programUrl + '/partners',
          headerView: true,
        },
        {
          key: 'industrial_parks',
          name: 'Industrial Parks',
          link: programUrl + '/industrialParks',
          headerView: true,
        },
        {
          insights: [
            !singleCountries.includes(programUrl) && {
              key: 'countries',
              name: 'Countries',
              link: programUrl + '/countries',
              headerView: true,
            },
            {
              key: 'value_chains',
              name: 'Value Chains',
              link: programUrl + '/sectors',
              headerView: true,
            },
            {
              key: 'analytics',
              name: 'Analytics',
              link: programUrl + '/analytics',
              headerView: true,
            },
            {
              key: 'publications',
              name: 'Publications',
              link: programUrl + '/publications',
              headerView: true,
            },
            {
              key: 'aboutDips',
              name: 'About DIPS',
              link: programUrl + '/about-dips',
              headerView: true,
            },
          ],
        },
        {
          key: 'disclaimer',
          name: 'Disclaimer',
          link: programUrl + '/disclaimer',
          headerView: false,
        },
        {
          key: 'news',
          name: 'News',
          link: programUrl + '/news',
          headerView: true,
        },
        {
          key: 'wwhConference',
          name: 'WWH Conference',
          link: programUrl + '/wwh-conference',
          headerView: true,
        },
      ]
    : [];

  //   links[2].insights = links[2].insights.filter(
  //     link =>
  //       (link.name || '').toLocaleLowerCase() !=
  //       (pageName || '').toLocaleLowerCase()
  //   );
  return links;
  //   return links.filter(link => link.name ? (link.name || '').toLocaleLowerCase() != (pageName || '').toLocaleLowerCase() : true );
};

export default getHeaderLInks;
