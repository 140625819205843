import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import GridContainer from '../components/Grid/GridContainer';
import GridItem from '../components/Grid/GridItem';
import getHeaderLInks from '../shared/headerLinks';
import theme from '../../config/theme';
import { TwitterFollowButton } from 'react-twitter-embed';

const ParagraphContent = css`
ul{
text-align: left;
}
  p,div{
    color: ${theme.fontColor.body};
    font-size: ${theme.fontSize.body};
    line-height: 1.55em;
    text-align: left;
    padding:0px;
  span{
    color: green;
  }
  }
  h2 {
    color: #3C4858;
    margin:40px 0px;
    margin-bottom: 15px;
    font-size: ${theme.fontSize.heading};
    line-height: 1.55rem;
    text-align:center;
    font-weight: ${theme.fontWeight.heading};
    font-family: ${theme.fontFamily.heading};
    span
    {
      color: #00acc1;
    }
  }
  h4 {
    color: #3C4858;
    margin:40px 0px;
    font-size:1rem;
    line-height: 1.20rem;
    text-align:left;
  }
  @media (max-width: 1024px) {

    p {
      padding:0px;
    }
    h4 {
      line-height: 40px;
    }
  }
  @media (max-width: 420px) {
    padding-left:0px;
    padding-right:0px;
    p {
      padding:0px;
    }
    h4 {
      font-size:1.12rem !important;
      line-height:25px;
    }
  }

}
`;

const container = css`
  padding-top: 2vh;
  min-height: 70vh;
`;
const TwitterSection = css`
  display: flex;
  justify-content: center;
`;

const ContactUs = ({ ...props }) => {
  const { t } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;

  const { pageHeader, headerLogo, headerLogoScroll } = (data || {}).node || {};

  let headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const footerLinks = [
    {
      name: 'FAQ',
      link: programLink + '/faq',
    },
    {
      name: 'About',
      link: programLink + '/about',
    },
    {
      name: 'Contact Us',
      link: programLink + '/contactUs',
    },
    {
      name: 'Disclaimer',
      link: programLink + '/disclaimer',
    },
  ];

  const helmetLink = `${Constants.appUrl}/about`;
  return (
    <Layout
      programLink={programLink}
      title={t('Contact Us')}
      headerLinks={headerLinks}
      footerLinks={footerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal Disclaimer')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <Container css={container}>
        <span css={ParagraphContent}>
          <h2>Contact Information</h2>
          <div css={TwitterSection}>
            <TwitterFollowButton screenName={'InvestinACP'} />
          </div>
          <GridContainer>
            <GridItem md={4}>
              <div>
                <b>UNIDO Headquarters</b>
              </div>
              <div>Vienna International Centre</div>
              <div>Wagramerstr. 5</div>
              <div>P.O. Box 300</div>
              <div>A-1400 Vienna</div>
              <div>Austria</div>
              <div>Tel: +43 (1) 26026-0</div>
              <div>Fax: +43 (1) 2692669</div>
              <div>Email: S.KRATZSCH@unido.org,</div>
              <div>B.PORTELLI@unido.org</div>
            </GridItem>
            <GridItem md={8}></GridItem>
          </GridContainer>
        </span>
      </Container>
    </Layout>
  );
};

export default ContactUs;

ContactUs.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
